import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

export const environment = {
  production: false,
  enterprise: false,
  light: false,
  olInOne: false,
  BACKEND_DOMAIN: 'https://api.dev.ol-hub.com/backend',
  BACKOFFCIE_URL: 'https://api.dev.ol-hub.com/backoffice',
  AUDIT_SERVICE_URL: 'https://api.dev.ol-hub.com/audit',
  DT_ENGINE_URL: 'https://api.dev.ol-hub.com/dt-engine',
  AUTH_SERVICE_URL: 'https://api.dev.ol-hub.com/auth-service',
  SOLUTION_CENTER_URL: 'https://api.dev.ol-hub.com/solution-center',
  RUNTIME_SERVICE_URL: 'https://api.dev.ol-hub.com/runtime',
  FLAGS_ROUTER_SERVICE_URL: 'https://api.dev.ol-hub.com/flags-router',
  NOTIFICATION_SERVICE_URL: 'https://api.dev.ol-hub.com/notifications',
  AUTH0_SPA_CLIENT_ID: 'BMVvjs3MafPaj7Fcqf7zy2VcOeODUGxE',
  AUTH0_DOMAIN: 'login-dev.ol-hub.com',
  AUTH0_CONNECTION: 'ol-auth0-db',
  MIXPANEL_TOKEN: '__MIXPANEL_TOKEN',
  HOTJAR_SITE_ID: '__HOTJAR_SITE_ID',
  // NGXS plugins
  plugins: [
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: true }),
  ],
  // mock auth for light hub
  MOCK_IAM_URL: '',
  MONITORING_URL: 'https://grafana.${region}.dev.ol-hub.com',
};
