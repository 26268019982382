/**
 * HUB Config File
 * Constants Used for define default & config values in our platform.
 */

import { environment } from '@env';

/**
 * Pagination
 */
export const paginationConfig = {
  DEFAULT_LIMIT: 10,
};

/**
 * used for creating hub entity(project, module, asset etc.) name
 * validations:
 * - not null
 * - not empty
 * - no spaces
 * - no special characters.
 * - lowercase letters only
 * - numbers
 * - hyphens
 * - underscores
 * - min length of 3
 */
export const ENTITY_NAME_REG_EXP = /^([a-z][-a-z0-9_]{2,})*$/;

/**
 * Used for model field name
 * validations:
 * - not null
 * - not empty
 * - no spaces
 * - no special characters.
 * - numbers
 * - number on first position
 */
export const ENTITY_NAME_FIRST_LETTER_NUMBER_REG_EXP = /^(?!\d+$)[-a-zA-Z0-9_]{1,}$/;

export const ENTITY_NAME_WITH_UPPER_CASE_REG_EXP = /^([-a-zA-Z0-9]{3,})*$/;

export const TRAIL_NAME_REG_EXP = /^[a-zA-Z][-a-zA-Z0-9]{2,}$/i;

export const USER_CREDENTIALS_REG_EXP = /^[\u0590-\u05FFa-zA-Z]+(( |-)[\u0590-\u05FFa-zA-Z]+)*$/;

/**
 * used for setting path as method OA parameter
 * validations:
 * starts with: /
 * avoid '//'
 * can be used {}
 */
/* eslint-disable */
export const PARTIAL_PATH_REG_EXP =
  /^(?:(?:[a-zA-Z]:)?(?:\/|\\{1,2})?(?:(?!\.{3,}\/|\.{3,}\\)[^\/\\:*?"<>|\r\n]+[\/\\])*(?:[^\/\\:*?"<>|\r\n]+))?[^\/\\:*?"<>|\r\n]*$/;
/* eslint-enable */

export const STRING_ONLY_SPACES_REG_EXP = /^\s*$/;

/**
 * used as hashmap in order to get the icon & title for a hub-entity
 */
export const HUB_ENTITY_CONFIG = {
  MODULE: {
    hubIconCls: 'icon-modules',
    title: 'Modules',
  },
  ASSET: {
    hubIconCls: 'icon-assets',
    title: 'Assets',
  },
  PROJECT: {
    hubIconCls: 'icon-projects',
    title: 'Projects',
  },
  SOLUTION: {
    hubIconCls: 'icon-solutions',
    title: 'Solutions',
  },
  CONTRACT: {
    hubIconCls: 'icon-contracts',
    title: 'Contracts',
  },
  METHOD: {
    hubIconCls: 'icon-methods',
    title: 'Methods',
  },
  RESOURCE: {
    hubIconCls: 'icon-attachments',
    title: 'Resources',
  },
  CONNECTION: {
    hubIconCls: 'icon-connections',
    title: 'Connections',
  },
  PROFILE: {
    hubIconCls: 'icon-profile',
    title: 'Profiles',
  },
};

export const IS_CLIENT_OS_WINDOWS = window.navigator.userAgent.toLowerCase().includes('windows');

/**
 * Form fields limits
 */
export const ENTITY_NAME_MAX_LENGTH = 60;
export const ENTITY_DESCRIPTION_MAX_LENGTH = 300;

/**
 * Hotjar default value
 */
export const HOTJAR_ORG_ID_PLACEHOLDER = '__HOTJAR_SITE_ID';

/**
 * Predefined list of validation error messages
 */
export const validationErrorMessages: Record<string, string> = {
  required: `Value is required and can't be empty.`,
  stringError: 'Value is not correct alphanumeric string value.',
  integerError: 'Value is not correct integer value.',
  integerSeparatedError: 'Value is incorrect, can be only integers separated by commas',
  doubleError: 'Value is not correct double value.',
  longError: 'Value is not correct long value.',
  binaryError: 'Value is not correct binary value.',
  duplicationConditionCaseValidator: 'Such Case already exists. Please define a unique case.',
  pattern: 'Value does not match with pattern',
  default_not_in_enums: 'Default should be one of Enum Values',
  whitespace_not_allowed: 'Enum values do not accept whitespaces',
  inContainIntegerError: 'Value not contain correct integer value',
  inContainDoubleError: 'Value not contain correct double value',
  inContainLongError: 'Value not contain correct long value',
  inContainBinaryError: 'Value not contain correct binary value',
};

export const MONACO_EDITOR_CONFIG: Record<string, unknown> = {
  theme: 'vs-dark',
  language: 'javascript',
  lineNumbers: 'on',
  readOnly: false,
  automaticLayout: true,
  allowUnusedLabels: true,
  minimap: {
    enabled: false,
  },
  overviewRulerBorder: false,
  scrollbar: {
    verticalScrollbarSize: 4,
    horizontalScrollbarSize: 4,
  },
  fixedOverflowWidgets: true,
};

/**
 * Default connection profile name
 */
export const DEFAULT_CONNECTION_PROFILE_NAME = '$default';

/**
 * Local storage keys
 */
export enum LocalStorageKey {
  IS_DONE_GET_STARTED = 'IS_DONE_GET_STARTED',
  WORK_AREA_CONTAINER_HEIGHT = 'WORK_AREA_CONTAINER_HEIGHT',
  GET_STARTED_VISITED_PAGES = 'GET_STARTED_VISITED_PAGES',
  NAVBAR_CLOSED = 'NAVBAR_CLOSED',
  TEST_SIDEBAR_WIDTH = 'TEST_SIDEBAR_WIDTH',
  OL_IN_ONE_USER = 'OL_IN_ONE_USER',
}

export const DefaultTagReason = 'Draft';

export const API_METHOD_TYPES = [
  { label: 'Get', value: 'GET' },
  { label: 'Post', value: 'POST' },
  { label: 'Put', value: 'PUT' },
  { label: 'Delete', value: 'DELETE' },
  { label: 'Patch', value: 'PATCH' },
];

export const API_METHOD_TYPE_COLORS = {
  PUT: 'hub-put-swatch-bg-color',
  DELETE: 'hub-delete-swatch-bg-color',
  GET: 'hub-get-swatch-bg-color',
  POST: 'hub-post-swatch-bg-color',
  PATCH: 'hub-patch-swatch-bg-color',
};

export const IS_HUB_LITE = environment.light || environment.olInOne;
export const IS_HUB_ENTERPRISE = environment.enterprise;
export const IS_HUB_ENTERPRISE_OR_HUB_LITE = IS_HUB_ENTERPRISE || IS_HUB_LITE;

/**
 *  Locale-related constants
 */

export const ENTITY_NAME_VALIDATION_MESSAGE = $localize`:@@ENTITY_NAME_VALIDATION_MESSAGE:Add unique [ENTITY] name, 3–60 characters. Use lower case letters and numbers and/or hyphens; no whitespace; start with lowercase letter.`;
export const SUPPORT_EMAIL = $localize`:@@SUPPORT_EMAIL:support@openlegacy.com`;
export const GENERIC_ERROR_MESSAGE = $localize`:@@GENERIC_ERROR_MESSAGE:Unexpected error occurred, please try again later or contact support at: ${SUPPORT_EMAIL}`;
