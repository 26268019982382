import { NgModule } from '@angular/core';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingLiteHubModule } from './app-routing-lite-hub.module';
import { MockIamProvider } from '@core/providers/mock-iam-auth.provider';
import { LiteHubHttpInterceptor } from '@core/providers/interceptors/lite-hub-http-interceptor';

@NgModule({
  declarations: [],
  imports: [AppRoutingLiteHubModule],
  exports: [AppRoutingLiteHubModule],
  providers: [
    {
      provide: HubAuthService,
      multi: false,
      deps: [HttpClient],
      useFactory: (httpClient: HttpClient) => {
        return new HubAuthService(null, null, new MockIamProvider(httpClient));
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LiteHubHttpInterceptor,
      multi: true,
    },
  ],
})
export class AppLiteHubModule {}
