import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface HubEnterpriseConfig {
  authDomain: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: HubEnterpriseConfig | undefined;

  constructor(private http: HttpClient) {}

  async loadConfig(): Promise<HubEnterpriseConfig> {
    const response = await this.http.get<HubEnterpriseConfig>('/config').toPromise();
    this.config = response;
    return response as HubEnterpriseConfig;
  }

  get authDomain(): string | undefined {
    if (!this.config) {
      /* eslint-disable no-console */
      console.error('config could not be loaded from app config service.');
    }

    return (this.config as HubEnterpriseConfig)?.authDomain;
  }
}
